var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"grids is-3-desktop is-1-mobile gap-20-desktop gap-20-mobile"},[_c('div',[_c('div',{staticClass:"box-tag"},[_vm._v("User Photo")]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"file"},[(!_vm.imageData)?_c('div',{staticClass:"user-photo",on:{"click":function($event){return _vm.uploadFile()}}}):_c('div',{staticClass:"user-photo",style:({ 'background-image': ("url( " + _vm.imageData + " )") }),on:{"click":function($event){return _vm.uploadFile()}}}),_c('input',{ref:"fileinput",attrs:{"hidden":"hidden","name":"image","type":"file"},on:{"input":_vm.onSelectFile}}),(!_vm.errorfileSize)?_c('label',[_vm._v(_vm._s(_vm.errorfileSize))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.profile),expression:"form.profile"}],attrs:{"hidden":"hidden","name":"image","type":"text"},domProps:{"value":(_vm.form.profile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "profile", $event.target.value)}}}),_c('div'),_c('span',{staticClass:"file-placeholder"},[_vm._v(" Click on image select your photo ")])])])]),_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h3',{staticClass:"box-tag"},[_c('span',[_vm._v("Login Information")])]),_c('div',{staticClass:"box"},[(_vm.errorMessage)?_c('div',{staticClass:"box-error"},[_c('div',{staticClass:"error_text"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Full Name")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.fName),expression:"user.fName"}],staticClass:"input",attrs:{"type":"text","placeholder":"name"},domProps:{"value":(_vm.user.fName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "fName", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Email")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"input",attrs:{"type":"text","placeholder":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.updateUser)}}},[_vm._v(" Save ")])])])]}}])})],1),_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h3',{staticClass:"box-tag"},[_c('span',[_vm._v("Change Password")])]),_c('div',{staticClass:"box"},[(_vm.errorMessagereset)?_c('div',{staticClass:"box-error"},[_c('div',{staticClass:"error_text"},[_vm._v(_vm._s(_vm.errorMessagereset))])]):_vm._e(),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Current Password")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Current Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.currentPassword),expression:"form.currentPassword"}],staticClass:"input",attrs:{"type":"text","placeholder":"Current password"},domProps:{"value":(_vm.form.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "currentPassword", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("New Password")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],staticClass:"input",attrs:{"type":"text","placeholder":"New password"},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "newPassword", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Repeat New Password")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"Repeat New Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmPassword),expression:"form.confirmPassword"}],staticClass:"input",attrs:{"type":"text","placeholder":"Repeat new password"},domProps:{"value":(_vm.form.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirmPassword", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.resetPassword)}}},[_vm._v(" Save password ")]),_c('br'),(_vm.succesMessage)?_c('p',{staticStyle:{"color":"green","font-weight":"bold","font-size":"16px"}},[_vm._v(_vm._s(_vm.succesMessage))]):_vm._e()])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header"},[_c('h3',[_vm._v("Account Preferences")])])}]

export { render, staticRenderFns }