<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>Account Preferences</h3>
      </div>
      <div class="grids is-3-desktop is-1-mobile gap-20-desktop gap-20-mobile">
        <div>
          <div class="box-tag">User Photo</div>
          <div class="box">
            <div class="file">
              <div class="user-photo" v-if="!imageData" @click="uploadFile()"></div>
              <div v-else class="user-photo" :style="{ 'background-image': `url( ${imageData} )` }" @click="uploadFile()">
              </div>
              <input hidden="hidden" name="image" ref="fileinput" type="file" @input="onSelectFile" />
              <!-- <h3 v-if="!errorfileSize">Please upload your company logo</h3> -->
              <label v-if="!errorfileSize">{{ errorfileSize }}</label>
              <!-- <p>Supported file .jpg .png .gif | Maximum 5mb</p> -->
              <input hidden="hidden" name="image" v-model="form.profile" type="text" />
              <div></div>
              <span class="file-placeholder">
                Click on image select your photo
              </span>
            </div>
          </div>
        </div>
        <div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <h3 class="box-tag">
              <span>Login Information</span>
            </h3>
            <div class="box">
              <div v-if="errorMessage" class="box-error">
                <div class="error_text">{{ errorMessage }}</div>
              </div>

              <div class="field">
                <label for="" class="label">Full Name</label>
                <div class="control">
                  <ValidationProvider name="Full Name" rules="required" v-slot="{ errors }">
                    <input v-model="user.fName" type="text" class="input" placeholder="name" />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Email</label>
                <div class="control">
                  <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                    <input v-model="user.email" type="text" class="input" placeholder="email" />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="button-group">
                <button @click="handleSubmit(updateUser)" class="button primary">
                  Save
                </button>
              </div>
            </div>
          </ValidationObserver>
        </div>
        <div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <h3 class="box-tag">
              <span>Change Password</span>
            </h3>
            <div class="box">
              <div v-if="errorMessagereset" class="box-error">
                <div class="error_text">{{ errorMessagereset }}</div>
              </div>
              <div class="field">
                <label for="" class="label">Current Password</label>
                <div class="control">
                  <ValidationProvider name="Current Password" rules="required|min:8" v-slot="{ errors }">
                    <input v-model="form.currentPassword" type="text" class="input" placeholder="Current password" />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">New Password</label>
                <div class="control">
                  <ValidationProvider name="New Password" rules="required|min:8" v-slot="{ errors }">
                    <input v-model="form.newPassword" type="text" class="input" placeholder="New password" />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Repeat New Password</label>
                <div class="control">
                  <ValidationProvider name="Repeat New Password" rules="required|min:8" v-slot="{ errors }">
                    <input v-model="form.confirmPassword" type="text" class="input" placeholder="Repeat new password" />
                    <span style="color: red; font-size: 12px">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <button @click="handleSubmit(resetPassword)" class="button primary">
                Save password
              </button>
              <br />
              <p v-if="succesMessage" style="color:green; font-weight: bold; font-size: 16px;">{{ succesMessage }}</p>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  GET_USER_FORM,
  RESET_PASSWORD,
  UPDATE_USER,
  IMAGE,
} from "@/graphql/User";
import apolloClient from "@/services/apollo-client";
import axios from "axios";
export default {
  data: () => ({
    user: [],
    errorMessage: "",
    errorMessagereset: "",
    errorfileSize: "",
    user: {
      fName: "",
      email: "",
      role: "",
    },
    imageData: "",
    form: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      profile: "",
    },
    succesMessage: ""
  }),
  methods: {
    async ImageUpdate() {
      await apolloClient.mutate({
        mutation: IMAGE,
        variables: {
          _id: this.user._id,
          image: this.imageData,
        },
      });
    },
    async updateUser() {
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        await apolloClient.mutate({
          mutation: UPDATE_USER,
          variables: {
            _id: this.user._id,
            name: this.user.fName,
            email: this.user.email,
            role: this.user.role,
            image: this.imageData,
          },
        });
        this.errorMessage = "";
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "This email registered by anouther":
            return (this.errorMessage = "ອີເມວນີ້ໄດ້ລົງທະບຽນແລ້ວ");
        }
      }
    },
    async resetPassword() {
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        const res = await apolloClient.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            currentPassword: this.form.currentPassword,
            newPassword: this.form.newPassword,
            confirmPassword: this.form.confirmPassword,
          },
        })
        this.succesMessage = res.data.changePW
        setTimeout(() => {
          this.succesMessage = ""
        }, 4500)
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "Password doesn't match from Database...!":
            return (this.errorMessagereset =
              "Password doesn't match from Database...!");
          case "Password doesn't match...!":
            return (this.errorMessagereset = "Password doesn't match...!");
        }
      }
    },

    async getUser() {
      const res = await apolloClient.query({
        query: GET_USER_FORM,
      });
      this.user = res.data.userInfo;
      this.imageData = this.user.image;
    },

    chooseImage() {
      this.$refs.fileinput.click();
    },
    uploadFile() {
      this.$refs.fileinput.click();
    },
    async onSelectFile() {
      const input = this.$refs.fileinput;
      const files = input.files;

      const file = files[0];

      const formData = new FormData();

      formData.append("file", file);
      if (file) {
        const filesize = file.size / 1024;
        const size = 5 * 1024;
        if (filesize < size) {
          axios.defaults.headers.common[
            "Authorization"
          ] = this.$store.getters.getCompanyToken;
          const res = await axios.post(`${this.$urlImage}`, formData);
          this.imageData = `${this.$urlImageShow}` + res.data.fileKey;
          if (res.data.message) {
            this.errorfileSize = "The file must be .JPG, .PNG. JPEG, .SVG";
            this.form.profile = null;
            this.imageData = null;
          }
          if (res.data.fileKey) {
            this.errorfileSize = file.name;
            this.form.profile = res.data.fileKey;
          }
        } else {
          this.errorfileSize = "The file Size is too much";
          this.imageData = null;
        }
      }
      this.ImageUpdate();
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped>
.error_text {
  color: red;
}

.box-tag {
  display: inline-block;
  font-size: var(--sm-font);
  background: var(--grey-color);
  padding: 5px 10px;
  color: var(--text-color);
  border-radius: 5px 5px 0 0;
  z-index: 0;
}

.box {
  position: relative;
  margin-bottom: 30px;

  .file {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;

    .user-photo {
      cursor: pointer;
      min-width: 250px;
      min-height: 250px;
      max-width: 250px;
      max-height: 250px;
      border-radius: 50%;
      background-image: url("../../public/images/user-avatar/user-avatar-02.png");
      background-position: center;
      background-size: cover;
    }

    .file-placeholder {
      margin-top: 10px;
    }
  }
}

.hline {
  margin-bottom: 50px;
}
</style>
